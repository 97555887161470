.titleText {
    font-size: 3vw;
    margin-top: 10%;
    color: #082038;
    font-family: "Space Mono", monospace;
  }
  
  .spacer {
    width: 100%;
    height: 2px;
    background-color: #082038;
  }

  .title {
    line-height: 1;
    font-size: 1.6vw;
    margin: 0;
    font-family: "Space Mono", monospace;
    color: #082038;
}

@media screen and (max-width: 960px) {
  .titleText {
    font-size: 8vw;
  }

  .spacer {
    margin-bottom: 6%;
  }
}