.readingBox {
    background-color: lightgray;
    overflow: hidden;
    flex-direction: row;
    align-items: center;
    height: fit-content;
    display: flex;
    width: 100%;
    margin-top: 5%;
    border-radius: 24px;
    justify-content: center;
    background-image: linear-gradient(to bottom, #ecf2e3, #B8C6AC);
    box-shadow: 2px 12px 40px 10px lightgray;
  }
  
  .bookContainer {
      width: 95%;
      height: 80%;
      justify-content: space-evenly;
      display: flex;
      margin-bottom: 2%;
      margin-top: 2%;
  }
  
  .bookBox {
      width: 25%;
      height: 80vh;
      text-align: center;
      margin-top: 3%;
  }
  
  .bookTitle {
    font-family: "Space Mono", monospace;
    margin-top: 5%;
    margin-bottom: 2%;
  }
  
  .author {
    font-family: "Space Mono", monospace;
    margin: 0;
  }
  
  .dune {
      /* size: 8vh; */
      width: 100%;
      height: 75%;
      border-radius: 8px;
  }

  @media screen and (max-width: 960px) {
    .readingBox {
      flex-direction: column;
    }

    .bookContainer {
      /* display: block; */
      flex-direction: column;
      align-items: center;
      height: fit-content;
      justify-content: center;
      margin-top: 5%;
    }

    .bookBox {
      width: 90%;
    }
  }