.card {
    display: flex;
    height: 100vh;
    width: 100%;
    margin-top: 5%;
    border-radius: 24px;
    justify-content: center;
}

@media screen and (max-width: 960px) {
    .card {
        margin-top: 10%;
    }
}