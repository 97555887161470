.container {
    width: 90%;
    display: flex;
    margin: 0;
    flex-direction: column;
  }
  
  .gradient {
    display: flex;
    overflow: hidden;
    flex-direction: column;
    align-items: center;
    height: auto;
    background-image: linear-gradient(to bottom, #ecf2e3, #B8C6AC);
    box-shadow: 2px 12px 40px 10px lightgray;
}

.videoContainer {
  display: flex;
  border-radius: 16px;
  margin-top: 4%;
  margin-bottom: 4%;
  width: 90%;
  height: 90%; 
  overflow: hidden;
  box-shadow: 2px 12px 40px 10px lightgray;
}

.video {
   width: 100%;
   height: 100%;
   
} 

.case {
  display: flex;
  text-align: center;
  width: 80%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
}

.caseTitle {
  margin-top: 10vh;
  font-size: 3.5vw;
  margin-bottom: 0;
}

.caseEntry {
  font-size: 1.8vw;
  line-height: 8vh;
}

.screenshot {
  height: 100%;
  width: 100%;
}

@media screen and (max-width: 960px) {

  .caseTitle {
    font-size: 8vw;
    margin-top: 6vh;
  }
  .caseEntry {
    font-size: 4vw;
    line-height: 6vh;
  }

  .img:nth-of-type(1) {
    margin-top: 10%;
  }
}

