.container {
    display: flex;
    margin-top: 5%;
}

.iconBox {
    width: fit-content;
    position: relative;
}

.rightHand {
    transform: scaleY(-1);
    height: auto;
    width: 25vw;
    padding-right: 20%;
}

.rightButton {
    position: absolute;
    top: 30%;
    left: 60%;
    transform: translate(-50%,-50%);
    z-index: 10;
    display: inline-block;
    border: none;
    border-radius: 8px;
    padding: 1rem 2rem;
    margin: 0;
    text-decoration: none;
    background-color: #0093E9;
    /* background-color: #0290E4; */
    color: #ffffff;
    font-family: sans-serif;
    font-size: 1rem;
    line-height: 0.1;
    cursor: pointer;
    text-align: center;
    transition: background 250ms ease-in-out, transform 150ms ease;
    -webkit-appearance: none;
    -moz-appearance: none;
}

.leftButton {
    position: absolute;
    top: 30%;
    left: 40%;
    transform: translate(-50%,-50%);
    z-index: 10;
    display: inline-block;
    border: none;
    border-radius: 8px;
    padding: 1rem 2rem;
    margin: 0;
    text-decoration: none;
    background-color: #F55650;
    color: #ffffff;
    font-family: sans-serif;
    font-size: 1rem;
    line-height: 0.1;
    cursor: pointer;
    text-align: center;
    transition: background 250ms ease-in-out, transform 150ms ease;
    -webkit-appearance: none;
    -moz-appearance: none;
}


.leftButton:hover {
    transition: background 500ms ease-in-out;
    background-color: #B53A27;
    /* opacity: 0.8; */
}

.rightButton:hover {
    transition: background 500ms ease-in-out;
    background-color:#00588D;
}

.leftHand {
    transform: scale(-1, -1);
    height: auto;
    width: 25vw;
}

@media screen and (max-width: 960px) {
    .container {
        display: none;
    }
}