@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;700&family=Space+Mono:wght@400;700&display=swap');

.container {
    display: flex;
    align-items: center;
    width: 100%;
    flex-direction: column;
}

.topLine {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.nameContainer {
    display: flex;
    width: 33%;
}

.name {
    font-family: "Space Mono", monospace;
    color: #082038;
}

.pages {
    display: flex;
    flex-direction: row;
    margin: auto;
    width: 33%;
    justify-content: center;
    font-family: "Space Mono", monospace;
    font-weight: 400;
    color: #082038;
}

.about {
    margin-right: 5%;
    color: #082038;
    margin-bottom: auto;
    border-bottom: 3px solid transparent;
}

.about:hover {
    border-bottom: 3px solid #0093E9;
}

.aboutActive {
    border-bottom: 3px solid #0093E9;
}

.work {
    margin-bottom: auto;
    border-bottom: 3px solid transparent;
}

.work:hover {
    border-bottom: 3px solid #F55650;
}

.workActive {
    border-bottom: 3px solid #F55650;
}

.iconContainer {
    width: 33%;
    display: flex;
    justify-content: flex-end;
}

.iconSpace {
    margin: 0 3%;
}

.spacer {
    width: 100%;
    height: 2px;
    background-color: #082038;
}