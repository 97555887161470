.box {
    height: 10%;
    width: 100%;
    display: flex;
    text-align: left;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}

.skillBox {
    background-color: lightgray;
    overflow: hidden;
    align-items: center;
    flex-direction: column;
    height: fit-content;
    display: flex;
    width: 100%;
    margin-top: 5%;
    border-radius: 24px;
    justify-content: center;
    background-image: linear-gradient(to bottom, #ecf2e3, #B8C6AC);
    box-shadow: 2px 12px 40px 10px lightgray;
  }

.skillContainer {
    height: 15vh;
    width: 80%;
    margin-top: 3%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  
  .skill {
      height: 100%;
      width: 30%;
      background-color: #0290E4;
      text-align: center;
      border-radius: 12px;
      align-items: center;
      justify-content: center;
      display: flex;
  }
  
  .skillContainer:last-child {
      margin-bottom: 3%;
  }
  
  .skillLabel {
      display: flex;
      color: white;
      font-size: 2vw;
      font-weight: 600;
      margin: 0;
  }
  
  .titleContainer {
      height: 20%;
      width: 100%;
      text-align: left;
      margin-left: 5%;
      margin-top: 2%;
  }
  
  .title {
      line-height: 1;
      font-size: 1.6vw;
      margin: 0;
      font-family: "Space Mono", monospace;
      color: #082038;
  }
  
  .toggleContainer {
      height: 2vh;
      width: 4vw;
      display: flex;
      flex-direction: row;
      margin: 3% 0;
      justify-content: space-evenly;
  }
  
  .designDot {
      height: 100%;
      background-color: white;
      width: 25%;
      border-radius: 50%;
      border: none;
      cursor: pointer;
  }
  
  .devDot {
      height: 100%;
      background-color: grey;
      width: 25%;
      border-radius: 50%;
      border: none;
      cursor: pointer;
  }

  @media screen and (max-width: 960px) {
      .skillContainer {
          height: 6vh;
          width: 95%;
          margin-top: 7%;
      }

      .skillLabel {
          font-size: 3vw;
      }

      .titleContainer {
        text-align: left;
        margin-left: 8%;
        margin-top: 5%;
    }

      .title {
          font-size: 4vw;
      }

      .toggleContainer {
          width: 20vw;
          height: 3vh;
          margin: 7% 0;
      }

      .designDot {
        width: 20%;
        border-radius: 50%;
      }

      .devDot {
        width: 20%;
        border-radius: 50%;
      }

     
  }