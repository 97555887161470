.aboutBox {
    overflow: hidden;
    align-items: center;
    height: fit-content;
    display: flex;
    width: 100%;
    margin-top: 5%;
    justify-content: center;
  }
  
  .aboutBox:last-child {
      margin-bottom: 5%;
  }
  
  .moody {
    height: 100vh;
    width: 50%;
    border-radius: 24px;
  }
  
  .textBox {
    margin-left: 3%;
    border-radius: 24px;
  }
  
  .intro {
    margin-bottom: 1%;
    text-align: center;
    justify-content: end;
  }
  
  .about {
    text-align: center;
    justify-content: center;
    line-height: 10vh;
    margin-top: 4%;
    color: #082038;
    font-size: 38px;
    font-family: "Space Mono", monospace;
    font-weight: 700;
  }
  
  .blurb {
    text-align: center;
    margin: 0 3% 5%;
    font-size: 1.6vw;
    line-height: 5vh;
    color: #082038;
    /* font-family: "DM Sans", sans-serif; */
    font-family: "Space Mono", sans-serif;
    font-weight: 400;
  }

  @media screen and (max-width: 960px) {
    .aboutBox {
      display: block;
    }

    .about {
      font-size: 8vw;
    }

    .moody {
      width: 100%;
      height: 50%;
      margin-top: 10%;
    }

    .blurb {
      font-size: 4vw;
    }
  } 