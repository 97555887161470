@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;700&family=Space+Mono:wght@400;700&display=swap');

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    flex-direction: column;
    margin-top: auto;
    margin-bottom: auto;
}

.titleContainer {
    justify-content: left;
    align-items: flex-start;
    width: 35vw;
    margin-top: 8%;
}

.line {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.title {
    font-size: 3.2vw;
    margin: 2% 0;
    padding-left: 5%;
    color: #082038;
    font-family: "Space Mono", monospace;
}

.body {
    display: flex;
}

.link {
    display: flex;
    margin: 0;
}

@media screen and (max-width: 960px) {
    .container {
        height: 100vh;
        width: 80%;
        align-items: center;
        justify-content: center;
    }
    .titleContainer {
        width: 100%;
        margin-top: 0;
        margin-bottom: 10%;
    }
    .title {
        font-size: 10vw;
    }

    .line {
        margin-left: 5%;
    }

    .buttonContainer {
        width: 50%;
        margin: 0;
    }

    .topBtn {
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        border-radius: 8px;
        margin: 5% 0 15% 0;
        width: 100%;
        height: 6vh;
        text-decoration: none;
        text-align: center;
        background-color: #0093E9;
        color: #ffffff;
        font-family: sans-serif;
        font-size: 1rem;
        line-height: 0.1;
        cursor: pointer;
        text-align: center;
        -webkit-appearance: none;
        -moz-appearance: none;
    }
    
    .bottomBtn {
        display: flex;
        border: none;
        border-radius: 8px;
        align-items: center;
        justify-content: center;
        margin: 0;
        width: 100%;
        height: 6vh;
        text-decoration: none;
        background-color: #F55650;
        color: #ffffff;
        font-family: sans-serif;
        font-size: 1rem;
        line-height: 0.1;
        cursor: pointer;
        text-align: center;
        -webkit-appearance: none;
        -moz-appearance: none;
    }
}