.imageContainer {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    margin-top: 5%;
}

.desktopImg {
    width: 48%;
    border-radius: 16px;
    box-shadow: 2px 12px 40px 10px lightgray;
}

.case {
    display: flex;
    text-align: center;
    width: 80%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
}

.caseTitle {
    margin-top: 10vh;
    font-size: 3.5vw;
    margin-bottom: 0;
}

.caseEntry {
    font-size: 1.8vw;
    line-height: 8vh;
}

.oneImg {
    width: 75%;
}

@media screen and (max-width: 960px) {
    .imageContainer {
        flex-direction: column;
    }

    .desktopImg {
        width: 100%;
        margin-top: 10%;
    }

    .caseTitle {
        font-size: 8vw;
        margin-top: 6vh;
      }
      .caseEntry {
        font-size: 4vw;
        line-height: 6vh;
        margin-bottom: 0;
      }
}