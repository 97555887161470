@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;700&family=Space+Mono:wght@400;700&display=swap');

.background {
    background-color: lightgray;
    overflow: hidden;
    flex-direction: column;
    align-items: center;
}

.box {
    height: 20%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;

}

.mobileBox {
    width: 95%;
    height: 80%;
    display: flex;
    margin-bottom: 3%;
    flex-direction: row;
    justify-content: center;
}

.phoneContainer {
    height: 100%;
    display: flex;
    justify-content: center;
    margin-left: 5%;
    align-items: center;
}

.phoneMain {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.phone {
    height: 100%;
}

.titleContainer {
    height: 20%;
    width: 100%;
    text-align: left;
    margin-left: 2.5%;
    margin-bottom: 3%;
}

.title {
    letter-spacing: 2px;
    line-height: 1;
    font-size: 1.6vw;
    margin: 0;
    font-family: "Space Mono", monospace;
}

.description {
    font-size: 1.2vw;
    margin-top: 0.6%;
    margin-bottom: 1%;
    font-family: "Space Mono", monospace;
}