.container {
  width: 90%;
  display: flex;
  margin: 0;
  flex-direction: column;
}

.titleText {
  font-size: 3vw;
  margin-top: 10%;
}

.spacer {
  width: 100%;
  height: 2px;
  background-color: black;
}

.img {
  margin-top: 5%;
  width: 100%;
  border-radius: 16px;
  background-color: lightgray;
  background-image: linear-gradient(to bottom, #ecf2e3, #B8C6AC);
  box-shadow: 2px 12px 40px 10px lightgray;
}

.gradient {
    display: flex;
    overflow: hidden;
    flex-direction: column;
    align-items: center;
    height: fit-content;
    background-image: linear-gradient(to bottom, #ecf2e3, #B8C6AC);
    box-shadow: 2px 12px 40px 10px lightgray;
}

.topRow {
    display: flex;
    height: 48%;
    width: 95%;
    justify-content: space-between;
    align-items: center;
    margin-top: 3%;
}

.bottomRow {
    display: flex;
    height: 48%;
    width: 95%;
    justify-content: space-between;
    align-items: center;
    margin-top: 3%;
    margin-bottom: 3%;
}

.mobileImg {
    display: flex;
    width: 30%;
    max-height: 100%;
    margin-bottom: 0;
}

.case {
    display: flex;
    text-align: center;
    width: 80%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
}

.caseTitle {
    margin-top: 10vh;
    font-size: 3.5vw;
    margin-bottom: 0;
}

.caseEntry {
    font-size: 1.8vw;
    line-height: 8vh;
}

@media screen and (max-width: 960px) {

  .caseTitle {
    font-size: 8vw;
    margin-top: 6vh;
  }
  .caseEntry {
    font-size: 4vw;
    line-height: 6vh;
  }

  .img:nth-of-type(1) {
    margin-top: 10%;
  }
}
